import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CouponHistory = () => {
    const { walletAddress } = useSelector((action) => {
        return action.auth;
      });
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = 'https://analogx.seedx.live/MerkleNew/public/api/CouponHistory';
        const requestData = {
        //   wallet_address: '0x8CaDc4a0B77A80842961a0bF8FD537829D961a57'
        wallet_address: walletAddress
        };

        const fetchOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        };

        const result = await fetch(apiUrl, fetchOptions);
        const data = await result.json();

        console.log("API Response:", data); 

        if (data && data.status === true && Array.isArray(data.data)) {
          setResponse(data.data);
        } else {
          console.error('Invalid API response:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            <div className="card-header border-0 pb-2 flex-wrap">
              <h4 className="heading me-2">Coupon History</h4>
            </div>
            <div className="card-body pt-2">
            <div className="table-responsive">
                    <table class="table text-nowrap">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Coupon Number</th>
                                <th>Reward</th>
                                <th>Status</th>
                                <th>Expire at</th>
                                <th>Coupon Reference</th>
                            </tr>
                        </thead>
                        <tbody>
                        {response && response.map((record) => (
                            
                        <tr key={record.id}>
                                <td>{record.id}</td>
                                <td>{record.coupon_number}</td>
                                <td>{record.reward}</td>
                                <td><span className={record.status === 'claimed' ? 'span_success' : 'span_danger'}>{record.status}</span></td>
                                <td>{record.expire_time}</td>
                                <td>{record.coupon_reference}</td>
                            </tr>
                    ))}
                            
                        </tbody>
                    </table>
                </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponHistory;
