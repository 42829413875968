import { lazy, Suspense, useEffect, useState } from "react";
import { configureChains, createClient, useAccount, WagmiConfig } from "wagmi";
import "@rainbow-me/rainbowkit/styles.css";
import { bscTestnet } from 'wagmi/chains';

import Index from "./jsx";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";


import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

import { publicProvider } from "@wagmi/core/providers/public";
import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  rainbowWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import {
  connectorsForWallets,
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";

import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import {
  userAllDetailsAction,
  userExistAction,
  walletAddressAction,
} from "./store/actions/AuthActions";
import { isUserExist } from "./helpers/getWeb3";
import KycStatus from "./jsx/pages/KycStatus";
import { getUserRandomId } from "./helpers/api";
import Buycoin from "./jsx/pages/Buycoin";

import { HomeMain } from "./HomeMain";


const SignUp = lazy(() => import("./jsx/pages/Registration"));

const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

var merkle = {
  id: 309,
  name: "WYZth Scan",
  network: "WYZth Scan",
  nativeCurrency: {
    decimals: 18,
    name: "WYZth",
    symbol: "WYZth",
  },
  rpcUrls: {
    default: { http: ["https://rpc-testnet.wyzthchain.org/"] },
  },
  blockExplorers: {
    etherscan: { name: "WYZth Scan", url: "https://testnet.wyzthscan.org/" },
    default: { name: "WYZth Scan", url: "https://testnet.wyzthscan.org/" },
  },
  contracts: {
    multicall3: {
      address: "0xDe749FC0804F010b10BB86A8F87EA27059354B9F",
      blockCreated: 703024,
    },
  },
  testnet: false,
};

const { chains, provider, webSocketProvider } = configureChains(
  [bscTestnet],
  [publicProvider()],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `${chain.rpcUrls.default.http[0]}`,
      }),
    }),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      metaMaskWallet({ chains }),
      trustWallet({ chains }),
      coinbaseWallet({ chains, appName: "My RainbowKit App" }),
      walletConnectWallet({ chains }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  // webSocketProvider,
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  
  const dispatch = useDispatch();
  const route = useLocation();
  const navigate = useNavigate()
  const { userExist, userAllDetails } = useSelector((action) => {
    return action.auth;
  });
  const { address, isDisconnected } = useAccount();


  useEffect(() => {
   
    dispatch(walletAddressAction(address));

    if (address) {
      isUserExist(address).then((res) => {
        console.log('res:::::')
        dispatch(userExistAction(res));
      });

    } else {
      dispatch(userExistAction(false));
    }
    
  }, [address]);

  // useEffect(() => {
  const { search, pathname } = props.router.location;

  

  useEffect(() => {
    if (userExist) {
      console.log("call App.js");
      navigate('/dashboard');  // Corrected the typo from 'dashbaord' to 'dashboard'
    }
  }, [userExist]);
  if (props.isAuthenticated) {
    // alert("hmm working")
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider
              chains={chains}
              modalSize="compact"
              theme={darkTheme({
                ...darkTheme.accentColors.green,
              })}
            >
              <Routes>

                <Route
                  path="/login"
                  element={
                    (userExist || address) ? (
                      <Navigate to="/dashboard" />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
              
              
              </Routes>

              {(userExist && address && (pathname === "/dashboard" || pathname==="/roiBonus" || pathname === "/partner" || pathname === "/myteam" || pathname === "/stakingBonus" || pathname === "/Level" || pathname === "/reward" || pathname === "/reward&Recognition" || pathname === "/claimBonus")) ? (
                <Index />
              ) : pathname === "/" ? (
                <HomeMain />
              ): pathname === "/register"? (
                <SignUp />
              ) : pathname === "/dashboard"&& !userExist || !address ? (
                <Login />
              ): (
               null
              )}
            </RainbowKitProvider>
          </WagmiConfig>
        </Suspense>
      </div>
    );
  }
}

export default withRouter(App);
