import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ScratchCard, { CUSTOM_BRUSH_PRESET } from "react-scratchcard-v2";

const Coupon = () => {
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });
  const [response_adr, setResponse_adr] = useState(null);
  const [response, setResponse] = useState(null);
  const [scratchedCards, setScratchedCards] = useState([]);
  const [visibleDetails, setVisibleDetails] = useState(null);
  const ref = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
 
  const fetchData = async () => {
    try {
      const apiUrl_adr = 'https://analogx.seedx.live/MerkleNew/public/api/CheckAddress';
      const requestData_adr = {
        // wallet_address: '0x8CaDc4a0B77A80842961a0bF8FD537829D961a57'
        wallet_address: walletAddress
      };

      const fetchOptions_adr = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData_adr)
      };

      const result_adr = await fetch(apiUrl_adr, fetchOptions_adr);
      const data_adr = await result_adr.json();

      // console.log("API Address Response:", data_adr.message);

        setResponse_adr(data_adr.message);

    } catch (error) {
      console.error('Error fetching Address data:', error);
    }
  };
  // ---------------check address start-------------------//
  useEffect(() => {


    fetchData();
  }, []);
  // ---------------check address close-------------------//

  // ---------------update address start------------------//
  const [formData, setFormData] = useState({
    wallet_address: walletAddress,
    house_no: '',
    title: '',
    rtitle: '',
    name: '',
    father_name: '',
    email: '',
    mobile: '',
    secondary_mobile: '',
    landmark: '',
    district: '',
    state: '',
    country: '',
    pincode: ''
  });

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    // console.log(name,value.length,"value")
    setFormData({
      ...formData,
      [name]: value
    });

    if (name === 'pincode' && value.length == 6) {
       await fetchAddressInfo(value);
     
    }
  };

  const fetchAddressInfo = async (pincode) => {
    try {
      const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
      const data = await response.json();

      if (data && data[0] && data[0].PostOffice && data[0].PostOffice.length > 0) {
       
        const postOffice = data[0].PostOffice[0];
        // console.log( postOffice, {...formData})
        setFormData({
          ...formData,
          pincode:postOffice.Pincode,
          district: postOffice.District || '',
          state: postOffice.State || '',
          country: postOffice.Country || '',
        });
      } else {
        setFormData({
          ...formData,
          district: '',
          state: '',
          country: '',
        });
      }
    } catch (error) {
      console.error('Error fetching address info:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);
      const response = await fetch('https://analogx.seedx.live/MerkleNew/public/api/UpdateAddress', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      
      const data = await response.json();
      setApiResponse(data);
      fetchData();
      // console.log("form data",data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
      // window.location.reload();
    }
  };
  // ---------------update address close------------------//

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = 'https://analogx.seedx.live/MerkleNew/public/api/CheckCoupon';
        const requestData = {
          // wallet_address: '0xb25e9163dc7699c530556c0e8c250308e2ef1ac8'
          wallet_address: walletAddress
        };

        const fetchOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        };

        const result = await fetch(apiUrl, fetchOptions);
        const data = await result.json();

        // console.log("API Response:", data);

        if (data && data.status === true && Array.isArray(data.data)) {
          setResponse(data.data);
        } else {
          // console.error('Invalid API response:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleScratch = async (id) => {
    try {
      if (!scratchedCards.includes(id)) {
        const claimUrl = 'https://analogx.seedx.live/MerkleNew/public/api/ClaimCoupon';
        const claimData = {
          // wallet_address: '0xb25e9163dc7699c530556c0e8c250308e2ef1ac8',
          wallet_address: walletAddress,
          coupon_number: response.find(record => record.id === id).coupon_number
        };

        const claimOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(claimData)
        };

        const claimResult = await fetch(claimUrl, claimOptions);
        const claimResponse = await claimResult.json();

        // console.log("Claim API Response:", claimResponse);

        setScratchedCards([...scratchedCards, id]);
        setVisibleDetails(id); 
      }
    } catch (error) {
      console.error('Error claiming coupon:', error);
    }
  };

  return (

    <div>
      <div className="row justify-content-center align-items-center">
					<div>
            {response_adr&&response_adr === 'Delivery Address Already Updated!' ? (
              <div className="row justify-content-center align-items-center">
        {response &&
          response.map((record) => (
            <div key={record.id} className="col-12 col-md-3">
              {scratchedCards.includes(record.id) ? (
                <div className={`win_card bg_success p-3`}>
                 
                    {visibleDetails === record.id && (
                      <div>
                      <img src="./../../../../img/win.png" className="img-fluid d-block mx-auto" style={{width:'125px'}}/>
                      <p className="win_head fa-beat">you won {record.reward}</p>

                        <p className="cp_dtl"><span className="cp_hed">Coupon Number :</span> {record.coupon_number}</p>
                        <p className="cp_dtl"><span className="cp_hed">Closing Reference :</span> {record.coupon_reference}</p>
                        <p className="cp_dtl"><span className="cp_hed">Expire at :</span> {record.expire_time}</p>
                      </div>
                    )}
                  
                </div>
              ) : (
                <div className='mb-5' onClick={() => handleScratch(record.id)}>
  {record.status === 'expired' ? (
    <img src="./../../../../img/expired.png" className="img-fluid mx-auto d-block" style={{ width: '300px', cursor: 'no-drop', borderRadius:'20px' }}/>
  ) : (
    <div>
      {record.status === 'claimed' ? (
        <div className="win_card p-3 bg_success">
          <img src="./../../../../img/win.png" className="img-fluid d-block mx-auto" style={{width:'125px'}} />
          <p className="win_head fa-beat">you won {record.reward}</p>
          <p className="cp_dtl"><span className="cp_hed">Coupon Number :</span> {record.coupon_number}</p>
          <p className="cp_dtl"><span className="cp_hed">Closing Reference :</span> {record.coupon_reference}</p>
          <p className="cp_dtl"><span className="cp_hed">Expire at :</span> {record.expire_time}</p>
        </div>
      ) : (
        <ScratchCard
          width={300}
          height={300}
          ref={ref}
          image='./../../../../img/scratch.jpg'
          finishPercent={10}
          customBrush={CUSTOM_BRUSH_PRESET}
          onComplete={() => {
            handleScratch(record.id);
            document.querySelector(".ScratchCard__Canvas").style.display = "none";
            // console.log("complete");
          }}
        />
      )}
    </div>
  )}
  {/* <p>Status: {record.status}</p> */}
</div>


              )}
            </div>
          ))}
              </div>
              ) : (
                <div className="row align-items-center justify-content-center">
                
                  <div className="col-12 col-md-12">
                  <div className="card">
                    <div className="card-body">
                    <p className="text-center mb-0">{response_adr}</p>
                    <h4 className="text-center pb-3">Update Your Address</h4>

                    {apiResponse && apiResponse.message === 'Address Updated Successfully' && (
                      <p className="text-success text-center">{apiResponse.message}</p>
                    )}
                    {apiResponse && apiResponse.message === 'Address Already Updated' && (
                      <p className="text-danger text-center">{apiResponse.message}</p>
                    )}
                    
<form onSubmit={handleSubmit}>
<div className="row align-items-center justify-content-center">

  <div className="col-12 col-md-4">

  <div className="d-flex ">
  <div className="form-group mb-4" style={{width:'25%'}}>
    <label>Title <span className="text-danger">*</span></label>
      <select name="title" className="form-control" value={formData.title} onChange={handleInputChange} required style={{borderRadius:'0.625rem 0 0 0.625rem'}}>
        <option selected="true">Select</option>
        <option value='Mr'>Mr</option>
        <option value='Ms'>Ms</option>
        <option value='Mrs'>Mrs</option>
        <option value='Miss'>Miss</option>
      </select>
    </div>
    
    <div className="form-group mb-4" style={{width:'100%'}}>
    <label>Full Name <span className="text-danger">*</span></label>
      <input
        type="text"
        className="form-control"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        style={{borderRadius:' 0 0.625rem 0.625rem 0'}}
        required
        onKeyDown={(event) =>
    (event.keyCode >= 65 && event.keyCode <= 90) ||
    event.keyCode === 8 ||
    event.keyCode === 9 ||
    event.keyCode === 32
      ? true
      : event.preventDefault()
  }
      />
        {apiResponse && apiResponse.message === 'All Fields are required !' && (
          <p className="text-danger">{apiResponse.message}</p>
        )}
      </div>
  </div>

  </div>

  <div className="col-12 col-md-4">

  <div className="d-flex ">
  <div className="form-group mb-4" style={{width:'25%'}}>
    <label>Title <span className="text-danger">*</span></label>
      <select name="rtitle" className="form-control" value={formData.rtitle} onChange={handleInputChange} required style={{borderRadius:'0.625rem 0 0 0.625rem'}}>
        <option selected="true">Select</option>
        <option value='S/O'>S/O</option>
        <option value='W/O'>W/O</option>
        <option value='D/O'>D/O</option>
        <option value='C/O'>C/O</option>
      </select>
    </div>
    
    <div className="form-group mb-4" style={{width:'100%'}}>
    <label>Father/Husband Name <span className="text-danger">*</span></label>
        <input
          type="text"
          className="form-control"
          name="father_name"
          value={formData.father_name}
          onChange={handleInputChange}
          required
          style={{borderRadius:' 0 0.625rem 0.625rem 0'}}
          onKeyDown={(event) =>
    (event.keyCode >= 65 && event.keyCode <= 90) ||
    event.keyCode === 8 ||
    event.keyCode === 9 ||
    event.keyCode === 32
      ? true
      : event.preventDefault()
  }
        />
        {apiResponse && apiResponse.message === 'All Fields are required !' && (
          <p className="text-danger">{apiResponse.message}</p>
        )}
      </div>
  </div>
  </div>

  <div className="col-12 col-md-4">
  <div className="form-group mb-4">
  <label>Mobile <span className="text-danger">*</span></label>
        <input
          type="text"
          className="form-control"
          name="mobile"
          value={formData.mobile}
          onChange={handleInputChange}
          required
          onKeyDown={(event) => {
    const allowedKeys =
      event.ctrlKey ||
      event.altKey ||
      (47 < event.keyCode &&
        event.keyCode < 58 &&
        event.shiftKey === false) ||
      (95 < event.keyCode && event.keyCode < 106) ||
      event.keyCode === 8 ||
      event.keyCode === 9 ||
      (event.keyCode > 34 && event.keyCode < 40) ||
      event.keyCode === 46;

    if (!allowedKeys) {
      event.preventDefault();
    }
  }}
        />
        {apiResponse && apiResponse.message === 'Invalid Mobile Number' && (
          <p className="text-danger">{apiResponse.message}</p>
        )}
      </div>
  </div>

  <div className="col-12 col-md-4">
  <div className="form-group mb-4">
  <label>Alternate Mobile</label>
        <input
          type="text"
          className="form-control"
          name="secondary_mobile"
          value={formData.secondary_mobile}
          onChange={handleInputChange}
          onKeyDown={(event) => {
    const allowedKeys =
      event.ctrlKey ||
      event.altKey ||
      (47 < event.keyCode &&
        event.keyCode < 58 &&
        event.shiftKey === false) ||
      (95 < event.keyCode && event.keyCode < 106) ||
      event.keyCode === 8 ||
      event.keyCode === 9 ||
      (event.keyCode > 34 && event.keyCode < 40) ||
      event.keyCode === 46;

    if (!allowedKeys) {
      event.preventDefault();
    }
  }}
        />
        {apiResponse && apiResponse.message === 'Invalid Secondary Mobile Number' && (
          <p className="text-danger">{apiResponse.message}</p>
        )}
      </div>
  </div>

  <div className="col-12 col-md-4">
  <div className="form-group mb-4">
  <label>Email Id <span className="text-danger">*</span></label>
        <input
          type="text"
          className="form-control"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        {apiResponse && apiResponse.message === 'Invalid Email' && (
          <p className="text-danger">{apiResponse.message}</p>
        )}
      </div>
  </div>

  <div className="col-12 col-md-4">
  <div className="form-group mb-4">
  <label>Address <span className="text-danger">*</span></label>
        <input
          type="text"
          className="form-control"
          name="house_no"
          value={formData.house_no}
          onChange={handleInputChange}
          required
        />
        {apiResponse && apiResponse.message === 'All Fields are required !' && (
          <p className="text-danger">{apiResponse.message}</p>
        )}
      </div>
  </div>

  <div className="col-12 col-md-4">
  <div className="form-group mb-4">
  <label>Landmark <span className="text-danger">*</span></label>
        <input
          type="text"
          className="form-control"
          name="landmark"
          value={formData.landmark}
          onChange={handleInputChange}
          required
        />
        {apiResponse && apiResponse.message === 'All Fields are required !' && (
          <p className="text-danger">{apiResponse.message}</p>
        )}
      </div>
  </div>

  <div className="col-12 col-md-4">
  <div className="form-group mb-4">
  <label>Pin Code <span className="text-danger">*</span></label>
<input
type="text"
className="form-control"
name="pincode"
value={formData.pincode}
onChange={handleInputChange}
required
onKeyDown={(event) => {
    const allowedKeys =
      event.ctrlKey ||
      event.altKey ||
      (47 < event.keyCode &&
        event.keyCode < 58 &&
        event.shiftKey === false) ||
      (95 < event.keyCode && event.keyCode < 106) ||
      event.keyCode === 8 ||
      event.keyCode === 9 ||
      (event.keyCode > 34 && event.keyCode < 40) ||
      event.keyCode === 46;

    if (!allowedKeys) {
      event.preventDefault();
    }
  }}
/>
{apiResponse && apiResponse.message === 'Invalid Pincode' && (
          <p className="text-danger">{apiResponse.message}</p>
        )}
</div>
  </div>
  
  <div className="col-12 col-md-4">
  <div className="form-group mb-4">
  <label>District<span className="text-danger">*</span></label>
        <input
          type="text"
          className="form-control"
          name="district"
          value={formData.district}
          onChange={handleInputChange}
          required
        />
        {apiResponse && apiResponse.message === 'All Fields are required !' && (
          <p className="text-danger">{apiResponse.message}</p>
        )}
      </div>
  </div>
  <div className="col-12 col-md-4">
  <div className="form-group mb-4">
  <label>State <span className="text-danger">*</span></label>
        <input
          type="text"
          className="form-control"
          name="state"
          value={formData.state}
          onChange={handleInputChange}
          required
        />
        {apiResponse && apiResponse.message === 'All Fields are required !' && (
          <p className="text-danger">{apiResponse.message}</p>
        )}
      </div>
  </div>
  <div className="col-12 col-md-4">
  <div className="form-group mb-4">
  <label>Country <span className="text-danger">*</span></label>
        <input
          type="text"
          className="form-control"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          required
        />
        {apiResponse && apiResponse.message === 'All Fields are required !' && (
          <p className="text-danger">{apiResponse.message}</p>
        )}
      </div>
  </div>
  
 

  <div className="col-12 col-md-12">
  <button type="submit" className="btn btn-primary mx-auto d-block px-md-5" disabled={isSubmitting}>
  {isSubmitting ? (
    <>
      Processing... <i class="fa-solid fa-spinner fa-spin ps-1"></i>
    </>
  ) : (
    <>
      Submit <i class="fa-solid fa-paper-plane ps-1"></i>
    </>
  )}
</button>
  </div>

</div>
      



    </form>
                    </div>
                  </div>
                  </div>
                </div>
              )}
          </div>
			  </div>

      </div>
  );
};

export default Coupon;
