import React, { useEffect, useState, useRef } from "react";
import { Tab, Nav } from "react-bootstrap";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import { Link } from "react-router-dom";
import { RewardIncomeDetail, getDirectTeam } from "../../../helpers/api";
import { useSelector } from "react-redux";
const RewardIncome = () => {
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });

  const [apidata, setApidata] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    RewardIncomeDetail(walletAddress, page).then((res) => {
      console.log(res, "res");
      setTotal(res?.total);
      setApidata(res);
      setLoading(true);
    });
  }, [page]);

  const handleChange = (e, p) => {
    getDirectTeam(walletAddress, p).then((res) => {
      setTotal(res?.total);
      setApidata(res?.data);
      setLoading(true);
    });
    //console.log(p, page, "page:::");
    setPage(p);
  };
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <Tab.Container defaultActiveKey="All">
                <div className="card-header border-0 pb-2 flex-wrap">
                  <h4 className="heading me-2">
                    Reward & Recognition Claim Details
                  </h4>
                </div>
                <div className="card-body pt-2">
                  <div
                    id="history_wrapper"
                    className="table-responsive dataTablehistory"
                  >
                    <div className="dataTables_wrapper no-footer">
                      <table
                        id="example"
                        className="table shadow-hover dataTable display"
                        style={{ minWidth: "845px" }}
                      >
                        <thead>
                          <tr>
                            <th>Txn Hash</th>
                            <th>User </th>
                            <th> Amount USDT</th>
                        
                            <th>Admin Commission</th>
                            <th>Received Amount</th>
                            <th>Status</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {apidata?.length > 0 ? (
                            apidata.map((e, index) => (
                              <tr key={index}>
                                <td>
                                  {" "}
                                  {e?.hash?.slice(0, 6)}...
                                  {e?.hash?.slice(-5)}
                                </td>
                                <td>
                                  {" "}
                                  {e?.user?.slice(0, 6)}...
                                  {e?.user?.slice(-5)}
                                </td>
                                <td>{e?.amountUsd}</td>
                             
                                <td> {e?.admin_commision}</td>
                               
                                <td>{e?.net_amount}</td>
                                <td>
                                  {e?.paid_status == "0" ? (
                                    <span
                                      class="badge bg-warning"
                                      text-secondary
                                    >
                                      {" "}
                                      Pending
                                    </span>
                                  ) : (
                                    <span class="badge bg-success">
                                      Success
                                    </span>
                                  )}{" "}
                                </td>
                                <td> {moment(e?.created_at).fromNow()}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                  No Data Found.
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
                        <div className="dataTables_info"></div>
                        <div
                          className="dataTables_paginate paging_simple_numbers mb-0"
                          id="application-tbl1_paginate"
                        >
                          {/* <Pagination
                            color="primary"
                            count={Math.ceil(total / 10)}
                            onChange={handleChange}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardIncome;
