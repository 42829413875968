import { toast } from "react-hot-toast";

export function copy_wallet() {
  var copyText = document.getElementById("copywallet");
  copyText.select();
  document.execCommand("copy");
  toast.success("wallet address copy");
}

export function copy_refral() {
  var copyText = document.getElementById("copy");
  copyText.select();
  document.execCommand("copy");
  toast.success("refral link copied");
}
